import { LeftOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Typography } from 'antd';
import React, { PropsWithChildren, ReactNode, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import styles from './pageItems.module.scss';
import { BackLink, DownloadOptions } from './types';

interface PageContentProps extends PropsWithChildren {
  title?: ReactNode;
  extra?: ReactNode[];
  backLink?: BackLink;
  downloadOptions?: DownloadOptions;
}

const getPageStyle = () =>
  `
  @page {
    size: auto;
    margin: 0mm;
  }
  html {
    background-color: #FFFFFF;
    margin: 0px;
  }
  body {
    margin: 5mm !important; /*content margin */
  }
  .printOnly {
    display: block;
  }
  .hidePrint {
    display: none;
  }
  `;

export const PageContent: React.FunctionComponent<PageContentProps> = ({ title, extra, children, backLink, downloadOptions }) => {
  const { t } = useTranslation('core');
  const contentRef = useRef<HTMLDivElement>(null);

  const reactToPrintContent = React.useCallback(() => {
    return contentRef.current;
  }, []);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: downloadOptions?.filename,
    removeAfterPrint: true,
    pageStyle: getPageStyle,
  });

  const navigate = useNavigate();

  const goBack = () => navigate(backLink?.to || '/');
  return (
    <div className={styles.page}>
      {backLink && (
        <Button type="link" icon={<LeftOutlined />} onClick={goBack} className={styles.back}>
          {backLink.text}
        </Button>
      )}
      <Card className={styles.content}>
        <div ref={contentRef}>
          <Row gutter={16}>
            <Col flex={1}>
              <Typography.Title level={2} color="primary">
                {title}
              </Typography.Title>
            </Col>
            {extra && extra.map((x, i) => <Col key={i}>{x}</Col>)}
            <Col className="hidePrint">
              {downloadOptions && downloadOptions.isEnabled ? (
                <Button icon={<PrinterOutlined />} onClick={() => handlePrint()}>
                  {t('actions.download')}
                </Button>
              ) : null}
            </Col>
          </Row>
          <div>{children}</div>
        </div>
      </Card>
    </div>
  );
};
